/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        $(".distributor-map-block address").each(function () {
          var address = $(this).text();
          var formattedAddress = address.replace(/,/g, ",<br>");
          $(this).html(formattedAddress);
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        //Dynamicly set paddingtop on body to account for header height
        var mastHeadHeight = $("#masthead").css("height");
        $(".header-padding").delay(3000).css("padding-top", mastHeadHeight);
        $(window).resize(function () {
          var mastHeadHeight = $("#masthead").css("height");
          $(".header-padding").css("padding-top", mastHeadHeight);
        });
        $(".popup-modal").magnificPopup({
          type: "inline",
        });
        $(".video-button").magnificPopup({
          disableOn: 700,
          type: "iframe",
          mainClass: "mfp-fade",
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
        });
        $(".popup-form-modal").magnificPopup({
          type: "inline",
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page

        function show_popup() {
          var now = new Date();
          var expiryDate = now.setDate(now.getDate() + 1);
          now.setTime(expiryDate);
          document.cookie =
            "dhglanguage=true;expires=" + now.toGMTString() + ";path=/";
          $("#languageModal").removeClass("hidden");
          $("#languageModal").modal("show");
        }

        $("#languageModal").on("hidden.bs.modal", function () {
          $("#languageModal").addClass("hidden");
        });

        if (document.cookie.indexOf("dhglanguage") === -1) {
          window.setTimeout(show_popup, 1000);
        }

        //Home header slider
        $(".home-slider").slick({
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 15000,
          fade: true,
          cssEase: "linear",
        });

        //Product category slider
        $(".product-category-slider").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: false,
          focusOnSelect: true,
          prevArrow:
            '<i class="product-slider-prev fa fa-chevron-left" aria-hidden="true"></i>',
          nextArrow:
            '<i class="product-slider-next fa fa-chevron-right" aria-hidden="true"></i>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1,
              },
            },
          ],
        });

        $("#what-we-do").on("scroll", function () {
          if (
            $(this).scrollTop() + $(this).innerHeight() >=
            $(this)[0].scrollHeight
          ) {
            console.log("end reached");
          }
        });

        var distance = $("#what-we-do").offset().top,
          $window = $(window);
        $window.scroll(function () {
          if ($window.scrollTop() >= distance) {
            // Your div has reached the top
            $(".home-banner").addClass("background--white");
            $(".home-banner").addClass("black-links");
            $(".home-banner").removeClass("white-links");
            $("#masthead .brand img").attr(
              "src",
              window.location.origin +
                window.location.pathname +
                "app/themes/dhg/dist/images/dhg-logo.svg"
            );
          } else {
            $(".home-banner").removeClass("background--white");
            $(".home-banner").removeClass("black-links");
            $(".home-banner").addClass("white-links");
            $("#masthead .brand img").attr(
              "src",
              window.location.origin +
                window.location.pathname +
                "app/themes/dhg/dist/images/dhg-logo-white.svg"
            );
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    //brand template
    page_template_template_brands_php: {
      init: function () {
        // JavaScript to be fired on clinical-research pages
        $(".timeline-main").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
          fade: true,
          asNavFor: ".timeline-nav",
        });
        $(".timeline-nav").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: ".timeline-main",
          dots: false,
          centerMode: true,
          focusOnSelect: true,
          prevArrow:
            '<i class="product-slider-prev fa fa-chevron-left" aria-hidden="true"></i>',
          nextArrow:
            '<i class="product-slider-next fa fa-chevron-right" aria-hidden="true"></i>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                centerMode: false,
                slidesToShow: 1,
              },
            },
          ],
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".image-popup-vertical-fit").magnificPopup({
          type: "image",
          closeOnContentClick: true,
          mainClass: "mfp-img-mobile",
          image: {
            verticalFit: true,
          },
        });
      },
    },
    page_template_template_clinical_resources_php: {
      init: function () {
        // JavaScript to be fired on clinical-research pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /**
         * Search fucntion
         * hideseek: http://vdw.github.io/HideSeek/
         */
        $("#search").hideseek({
          list: ".search-list",
          nodata:
            "Sorry there are no documents matching your criteria, please try again.",
          attribute: "text",
          highlight: false,
          ignore_accents: false,
          hidden_mode: true,
        });

        $(".product-search").on("change", function () {
          var product = $(this).val();
          $("#search").focus();
          $("#search").val(product);
          $("#search").keyup();
        });

        $("#search").on("_after", function () {
          $(".search-list").slideDown("medium");
          $("i.fa-close").fadeIn();
        });

        $("i.fa-close").on("click", function () {
          $(this).fadeOut();
          $(".search-list").slideUp("medium");
          $("#search").val("");
        });

        /**
         * Get URL Paramater
         * @param {*} urlParam
         */
        function GetURLParameter(urlParam) {
          var sPageURL = window.location.search.substring(1);
          var sURLVariables = sPageURL.split("&");
          for (var i = 0; i < sURLVariables.length; i++) {
            var urlParameterName = sURLVariables[i].split("=");
            if (urlParameterName[0] === urlParam) {
              return urlParameterName[1];
            }
          }
        }

        //if url parameter is set, set the value of the select
        var catURLParam = new GetURLParameter("cat");
        if (typeof catURLParam !== "undefined") {
          $(".category-search").val(catURLParam);
        }

        //Change the filter by category
        $(".category-search").on("change", function () {
          var categoryValue = this.value;
          var resourceWindow = $(this).attr("data-target");
          var resourceWindowFilter = resourceWindow + "?cat=" + categoryValue;
          window.open(resourceWindowFilter, "_self");
        });
      },
    },
    //Single product
    single_products: {
      init: function () {
        // JavaScript to be fired on the single product page

        $(".read-more-description").hide();
        $(".read-more-toggle").on("click", function () {
          var readLessString = $(this)[0].dataset.lessText;
          var readMoreString = $(this)[0].dataset.moreText;

          $(this).toggleClass("active");
          $(".read-more-description").slideToggle();
          if ($(this).hasClass("active")) {
            $(this).find("a").html(readLessString);
          } else {
            $(this).find("a").html(readMoreString);
          }
        });

        $(".product-gallery").magnificPopup({
          delegate: "a",
          type: "image",
          tLoading: "Loading image #%curr%...",
          mainClass: "mfp-img-mobile",
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError:
              '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
              return (
                $("h1.entry-title").html() +
                "<small>Direct Healthcare Group</small>"
              );
            },
          },
        });

        $(".option-gallery").magnificPopup({
          delegate: "a",
          type: "image",
          tLoading: "Loading image #%curr%...",
          mainClass: "mfp-img-mobile",
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError:
              '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
              return (
                item.el.attr("title") + "<small>Direct Healthcare Group</small>"
              );
            },
          },
        });

        //Product related slider
        $(".product-related-slider").slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: false,
          focusOnSelect: true,
          prevArrow:
            '<i class="product-slider-prev fa fa-chevron-left" aria-hidden="true"></i>',
          nextArrow:
            '<i class="product-slider-next fa fa-chevron-right" aria-hidden="true"></i>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1,
              },
            },
          ],
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
